'use client';

import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export function ReactQueryClientProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        /* Important defaults - these ensures data set during SSR does not expire */
        defaultOptions: {
          queries: { staleTime: Infinity, gcTime: Infinity },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      {children}
    </QueryClientProvider>
  );
}
