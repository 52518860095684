import { IntlError, IntlErrorCode } from 'next-intl';

import { getLogger } from '@/modules/observability/logging';

export function handleNextIntlError(error: IntlError) {
  if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'development') {
    console.error(`Intl error - missing translation`, { error });
  }
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    getLogger().info(`Intl error - missing translation`, { error });
  } else {
    getLogger().error(`Intl error`, { error });
  }
}

export function getMessageFallback({
  key,
}: {
  error: IntlError;
  key: string;
  namespace?: string | undefined;
}) {
  return key;
}
