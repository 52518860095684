'use client';

import React from 'react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { useParams } from 'next/navigation';

import { getMessageFallback, handleNextIntlError } from './helpers';

type Props = {
  children: React.ReactNode;
  messages: Record<string, string | AbstractIntlMessages>;
};

export function LanguageProvider({ children, messages }: Props) {
  const params = useParams();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const locale =
    (params.locale as string) || process.env.NEXT_PUBLIC_DEFAULT_LOCATION;

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      getMessageFallback={getMessageFallback}
      onError={handleNextIntlError}
      timeZone={timeZone}
    >
      {children}
    </NextIntlClientProvider>
  );
}
