import { AxiosCompatibleError } from './types';

export function isOctetStreamResponse(response: Response) {
  const contentType = response.headers.get('Content-Type');

  return contentType && contentType.includes('application/octet-stream');
}

export function isJSONResponse(response: Response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    return true;
  }

  return false;
}

export function isTextResponse(response: Response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('text')) {
    return true;
  }

  return false;
}

export function queryParamsFromObject(params: Record<string, unknown>) {
  const urlParams = new URLSearchParams();
  for (const [k, v] of Object.entries(params)) {
    if (v !== null && typeof v !== 'undefined') {
      urlParams.append(k, String(v));
    }
  }

  return '?' + urlParams.toString();
}

export function isFetchError(err: unknown): err is AxiosCompatibleError {
  return typeof err === 'object' && err !== null && 'response' in err;
}
