import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@depop/web-ui-kit/dist/assets/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/usr/src/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"src/modules/fonts/config.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./GT-America-Italic.woff2\",\"weight\":\"normal\",\"style\":\"italic\"},{\"path\":\"./GTAmerica-Regular.woff2\",\"weight\":\"bold\",\"style\":\"normal\"}],\"variable\":\"--font-GtAmerica\",\"display\":\"swap\"}],\"variableName\":\"GTAmerica\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"src/modules/fonts/config.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./GTAmerica-ExtendedRegular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"./GT-America-Extended-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"}],\"variable\":\"--font-GtAmericaExtended\",\"display\":\"swap\"}],\"variableName\":\"GTAmericaExtended\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"src/modules/fonts/config.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./GTAmerica-ExpandedRegular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"./GT-America-Expanded-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"}],\"variable\":\"--font-GtAmericaExpanded\",\"display\":\"swap\"}],\"variableName\":\"GTAmericaExpanded\"}");
;
import(/* webpackMode: "eager", webpackExports: ["RefererProvider"] */ "/usr/src/app/src/modules/activityTracker/RefererProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/usr/src/app/src/modules/i18n/LanguageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryClientProvider"] */ "/usr/src/app/src/modules/ReactQuery/ReactQueryClientProvider.tsx");
