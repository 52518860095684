export const allowedSpans = [
  'NextServer.getServerRequestHandler',
  'NextServer.getRequestHandler',
  'LoadComponents.loadComponents',
  'NextNodeServer.findPageComponents',
  'BaseServer.handleRequest',
  'BaseServer.renderToResponseWithComponents',
  'BaseServer.renderToResponse',
  'BaseServer.pipe',
  'BaseServer.render',
  'AppRender.fetch',
  'AppRender.getBodyResult',
];

export enum MetricLayers {
  'HandleRequest' = 'HandleRequest',
  'RenderRoute' = 'RenderRoute',
  'ServerFetch' = 'ServerFetch',
  'GenerateMetadata' = 'GenerateMetadata',
  'GetServerRequestHandler' = 'GetServerRequestHandler',
  'GetRequestHandler' = 'GetRequestHandler',
  'LoadComponents' = 'LoadComponents',
  'FindPageComponents' = 'FindPageComponents',
  'RenderToResponseWithComponents' = 'RenderToResponseWithComponents',
  'RenderToResponse' = 'RenderToResponse',
  'Pipe' = 'Pipe',
  'Render' = 'Render',
}

/**
 * These are our custom layers for stdv1 metrics collection.
 *
 * You can see these as analogous to controller/service/client layers
 * we use in other services.
 */
export const spanTypeToLayer: Record<string, MetricLayers> = {
  'BaseServer.handleRequest': MetricLayers.HandleRequest,
  'AppRender.getBodyResult': MetricLayers.RenderRoute,
  'AppRender.fetch': MetricLayers.ServerFetch,
  'ResolveMetadata.generateMetadata': MetricLayers.GenerateMetadata,
  'NextServer.getServerRequestHandler': MetricLayers.GetServerRequestHandler,
  'NextServer.getRequestHandler': MetricLayers.GetRequestHandler,
  'LoadComponents.loadComponents': MetricLayers.LoadComponents,
  'NextNodeServer.findPageComponents': MetricLayers.FindPageComponents,
  'BaseServer.renderToResponseWithComponents':
    MetricLayers.RenderToResponseWithComponents,
  'BaseServer.renderToResponse': MetricLayers.RenderToResponse,
  'BaseServer.pipe': MetricLayers.Pipe,
  'BaseServer.render': MetricLayers.Render,
};

/**
 * When adding a new page, you need to define the classFunctionName here
 * so we have a mapping, which makes defining overrides easier and in
 * general makes working with the dashboards easier.
 *
 * You can find out the correct route pattern for your page by setting
 * debug to true in the StatsDExporter class.
 *
 * If not added, a default will be computed from the path.
 */
export const routeToClassFnName: Record<string, string> = {
  '/[locale]': 'localisedHomePage',
  '/[locale]/sell': 'sellOnDepopPage',
  '/[locale]/bag': 'bagPage',
  '/[locale]/password-reset': 'passwordResetPage',
  '/[locale]/password-reset/[token]': 'passwordResetTokenPage',
  '/[locale]/sellinghub/shipping': 'sellingHubShippingPage',
  '/[locale]/sellinghub/stats': 'sellingHubStatsPage',
  '/[locale]/sellinghub/selling/active': 'sellingHubSellingActivePage',
  '/[locale]/sellinghub/selling/sold': 'sellingHubSellingSoldPage',
  '/[locale]/sellinghub/boost/all': 'sellingHubBoostAllPage',
  '/[locale]/sellinghub/boost/boosted': 'sellingHubBoostBoostedPage',
  '/[locale]/sellinghub/drafts/readytopost': 'sellingHubDraftsReadyToPostPage',
  '/[locale]/sellinghub/drafts/incomplete': 'sellingHubDraftsIncompletePage',
  '/[locale]/sellinghub/drafts': 'sellingHubBulkListingPage',
  '/[locale]/sellinghub/vacation': 'sellingHubVacationPage',
  '/[locale]/[username]': 'shopPage',
  '/[locale]/[username]/selling': 'shopSellingPage',
  '/[locale]/[username]/sold': 'shopSoldPage',
  '/[locale]/[username]/likes': 'shopLikesPage',
  '/[locale]/[username]/saved': 'shopSavedPage',
  '/[locale]/search': 'searchPage',
  '/[locale]/brands/a-z': 'brandsAtoZPage',
  '/[locale]/brands/a-z/[letter]': 'brandsLetterPage',
  '/[locale]/login': 'loginPage',
  '/[locale]/login/mfa': 'loginMFAPage',
  '/[locale]/login/magic-link': 'requestMagicLinkPage',
  '/[locale]/login/magic-link/check-inbox': 'magicLinkCheckInboxPage',
  '/[locale]/login/magic-link/invalid-link': 'magicLinkInvalidLinkPage',
  '/[locale]/login/magic-link/missing-link': 'magicLinkInvalidLinkPage',
  '/[locale]/login/magic-link/verify/[token]': 'magicLinkVerifyPage',
  '/[locale]/signup': 'signupPage',
  '/[locale]/signup/email': 'signupEmailPage',
  '/[locale]/signup/apple': 'signupApplePage',
  '/[locale]/signup/google': 'signupGooglePage',
  '/[locale]/signup/phone': 'signupPhonePage',
  '/[locale]/signup/phone-confirm': 'signupPhoneConfirmPage',
  '/{locale]/start-selling/dob': 'sellerOnboardingDateOfBirthPage',
  '/[locale]/start-selling/billing-address':
    'sellerOnboardingBillingAddressPage',
  '/[locale]/start-selling/depop-balance': 'sellerOnboardingDepopBalancePage',
  '/[locale]/start-selling/paypal': 'sellerOnboardingPaypalPage',
  '/[locale]/start-selling/company': 'sellerOnboardingCompanyPage',
  '/[locale]/start-selling/stripe/account-verification/failure':
    'sellerOnboardingStripeSuccessPage',
  '/[locale]/start-selling/stripe/account-verification/success':
    'sellerOnboardingStripeFailurePage',
  '/[locale]/products/[slug]': 'productPage',
  '/[locale]/products/[slug]/manage': 'productManagePage',
  '/[locale]/(browse)/brands/[slug]': 'browseBrandsPage',
  '/[locale]/(browse)/brands/[slug]/c/[...category]':
    'browseBrandsCategoryPage',
  '/[locale]/(browse)/category/[...category]': 'browseCategoryPage',
  '/[locale]/(browse)/explore': 'browseExplorePage',
  '/[locale]/pay': 'checkoutLandingPage',
  '/[locale]/pay/return': 'checkoutReturnPage',
  '/[locale]/pay/success': 'checkoutSuccessPage',
  '/[locale]/pay/failure': 'checkoutFailurePage',
  '/[locale]/settings': 'settingsPage',
  '/[locale]/settings/email_preferences': 'emailPreferencesPage',
  '/[locale]/settings/seller_preferences': 'sellerPreferencesPage',
  '/[locale]/settings/push_preferences': 'pushPreferencesPage',
};

/* Key and Value *must* be the same, apart from that these can be whatever you want */
export enum Stdv1Stat {
  'cookie.trackers.init.failed' = 'cookie.trackers.init.failed',
  'performance.tracking.failed' = 'performance.tracking.failed',
  'canary.metric.server.success' = 'canary.metric.server.success',
  'canary.metric.client.success' = 'canary.metric.client.success',
  'errors.errorBoundary.caught' = 'errors.errorBoundary.caught',
  'errors.http.networkError' = 'errors.http.networkError',
  'errors.user.parse.failed' = 'errors.user.parse.failed',
  'magic_link.tracking.login.success' = 'magic_link.tracking.login.success',
  'magic_link.tracking.login.browser_matched' = 'magic_link.tracking.login.browser_matched',
  'magic_link.tracking.login.browser_not_matched' = 'magic_link.tracking.login.browser_not_matched',
  'magic_link.tracking.redirect.checkout.has_products.success' = 'magic_link.tracking.redirect.checkout.has_products.success',
  'magic_link.tracking.redirect.checkout.has_products.failure' = 'magic_link.tracking.redirect.checkout.has_products.failure',
  'signup.signup_request.phone_error' = 'signup.signup_request.phone_error',
  'signup.signup_request.email_error' = 'signup.signup_request.email_error',
  'signup.signup_request.password_error' = 'signup.signup_request.password_error',
  'signup.signup_request.username_error' = 'signup.signup_request.username_error',
  'signup.signup_request.unknown_error' = 'signup.signup_request.unknown_error',
  'signup.signup_request.other_error' = 'signup.signup_request.other_error',
  'experiments.preview_request.prefetch.failed' = 'experiments.preview_request.prefetch.failed',
  'apple.auth.user_trigger_new_signin_flow_error' = 'apple.auth.user_trigger_new_signin_flow_error',
  'apple.auth.popup_closed_by_user_error' = 'apple.auth.popup_closed_by_user_error',
}

// Discard errors from the following sources:
const IGNORED_SOURCES = [
  'safari-extension',
  'ms-browser-extension',
  'bookmarklet',
  'safari-web-extension',
  'moz-extension',
  'get663',
  'sift',
  'chrome-extension',
  'cdn.branch',
  'pinimg',
  'IDBDatabase',
  'dbankcloud.com',
  'js.stripe.com',
  'accounts.google.com',
  'segment',
  'clarity.ms',
  'googletagmanager',
  'd118.org',
  'fp.sn.ifl.net',
  'pinterest',
  'bing',
  'doubleclick',
  'schools-blocked',
  'facebook',
  'gstatic',
];

// Discard errors containing the following keywords:
const IGNORED_ERRORS = [
  'getComputedStyle',
  'UET',
  'Network Error',
  'AxiosError',
  'connect-src',
  'Failed to fetch',
  'chunk',
  'Script error',
  'Load failed',
  'eval',
  'ResizeObserver',
];

export const IGNORED_KEYWORDS = [...IGNORED_ERRORS, ...IGNORED_SOURCES];

export const IGNORED_CLIENT_ERROR_MESSAGES = [
  'Network Error',
  'Failed to fetch',
  'Load failed',
  'Loading chunk',
];

export const DD_SERVICE = 'web-default-website-server';
